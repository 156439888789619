// import './vendor/body-scroll-lock';
import './vendor/swiper';
import Splitting from './vendor/splitting';
import sal from './vendor/sal';
import anime from './vendor/anime';
import Pristine from './vendor/pristine';

window.Splitting = Splitting;
window.sal = sal;
window.anime = anime;
window.Pristine = Pristine;
